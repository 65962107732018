import axios from 'axios';
import { HttpService } from './http';

class NotesService extends HttpService {
  apiBaseUrl: string;
  apiBaseUrlV2: string;
  constructor(apiBaseUrl: string) {
    super();
    this.apiBaseUrl = apiBaseUrl;
    this.apiBaseUrlV2 = `${apiBaseUrl}/v2/notes`;
  }

  async getNotes() {
    try {
      const response: APIResponse<INotesResponse> = await this.get(`${this.apiBaseUrlV2}/`);
      return response?.data?.notes;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching notes:', error);
      } else {
        console.error('Unexpected error:', error);
      }

      throw error;
    }
  }

  async addNote(data: any) {
    try {
      const response: APIResponse<INotesResponse> = await this.post(`${this.apiBaseUrlV2}/`, data);
      return response?.data?.note;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error adding note:', error);
      } else {
        console.error('Unexpected error', error);
      }

      throw error;
    }
  }
  async deleteNote(noteId: number) {
    try {
      const response: any = await this.delete(`${this.apiBaseUrlV2}/${noteId}`);
      return response.data?.data;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error deleting note:', error);
      } else {
        console.error('Unexpected error', error);
      }

      throw error;
    }
  }
  async updateNote(noteId: number | null, data: any) {
    try {
      const response: APIResponse<INotesResponse> = await this.patch(`${this.apiBaseUrlV2}/${noteId}`, data);
      return response?.data?.updatedNotes;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error updating note:', error);
      } else {
        console.error('Unexpected error', error);
      }

      throw error;
    }
  }

  async updateNotesOrder(data: any) {
    try {
      await axios.patch(`${this.apiBaseUrlV2}/order`, data);
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error updating notes order:', error);
      } else {
        console.error('Unexpected error', error);
      }

      throw error;
    }
  }
}

const notesService = new NotesService(`${process.env.REACT_APP_BASE_URL}`);
export default notesService;
