import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openActivityModal, openUploadModal } from '../../../Atoms/Activities/activityAtom';
// import { collegeRecommendationsExpandedAtom, recommendationsAtom } from '../../../Atoms/Colleges/colleges';
import { AnalyticsScreenName, HEADER_LINKS_KOLLEGIO } from '../../../Constants/constants';
import { getImage } from '../../../Utils/common';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import NavLink from '../../../lib/UI-Components/NavLink';
interface LogoSectionProps {
  isUserOnPublicRoute: any;
  location: any;
  setLocation: any;
  setAreNotesSelected: any;
  setIsGuideSelected: any;
  isPrivateRoute: boolean;
}

const LinksSection = ({
  isUserOnPublicRoute,
  location,
  setLocation,
  setAreNotesSelected,
  setIsGuideSelected,
  isPrivateRoute,
}: LogoSectionProps) => {
  const { trackEvent } = useMixpanel();
  const setIsOpen = useSetAtom(openActivityModal);
  const setIsUploadModalOpen = useSetAtom(openUploadModal);
  const navigate = useNavigate();

  const proceedWithNavigation = (key: string) => {
    setLocation(key);
    setAreNotesSelected(false);
    setIsGuideSelected(false);
    setIsOpen(false);
    setIsUploadModalOpen(false);
    trackEvent(`Open ${key}`, {
      screenName: AnalyticsScreenName.ScreenHeader,
      label: `Click on ${key} link in Header`,
      isLink: true,
      query: '#menu-header a',
    });
    navigate(`/${key.toLowerCase()}`);
  };

  const handleNavigation = (key: string) => {
    proceedWithNavigation(key);
  };

  return (
    <>
      {!isUserOnPublicRoute && isPrivateRoute && (
        <HStack
          id='menu-header'
          h='full'
          as={'nav'}
          justifyContent={'center'}
          display={['none', 'none', 'none', 'flex', 'flex']}
        >
          {Object.entries(HEADER_LINKS_KOLLEGIO).map(([key, value]) => (
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(key);
              }}
              key={key}
              to={`/${key.toLowerCase()}`}
              borderRadius={0}
              borderBottom={location === key ? '3px solid #3E8A75' : 'none'}
            >
              <Box key={key} px={3} h='full'>
                <VStack spacing={0} h={'100%'}>
                  <Image
                    src={location === key ? getImage('green' + value.toLowerCase()) : getImage(value.toLowerCase())}
                    w={4}
                    h={4}
                  />
                  <Text
                    color={location === key ? 'primaryLight' : 'gray4'}
                    fontWeight={location === key ? 'semibold' : 'normal'}
                    fontSize={'sm'}
                  >
                    {value}
                  </Text>
                </VStack>
              </Box>
            </NavLink>
          ))}
        </HStack>
      )}
    </>
  );
};

export default LinksSection;
