import { HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { DislikeIcon, LikeIcon } from '../../../Assets/icons';

import loggingService from '../../../Services/logging';

import { likeState } from '../../../Constants/logging';

const AIPerformanceLog = ({ logId }: any) => {
  const [currentLikeState, setCurrentLikeState] = useState(likeState.None);

  const { mutate } = useMutation({
    mutationFn: ({ inputLogId, inputCurrentLikeState }: { inputLogId: any; inputCurrentLikeState: likeState }) =>
      loggingService.logAiPerformance({ log_id: inputLogId, like: inputCurrentLikeState }),
  });

  const likeAIPerformance = async () => {
    if (currentLikeState === likeState.Like) {
      setCurrentLikeState(likeState.None);
      mutate({ inputLogId: logId, inputCurrentLikeState: likeState.None });
    } else {
      setCurrentLikeState(likeState.Like);
      mutate({ inputLogId: logId, inputCurrentLikeState: likeState.Like });
    }
  };

  const dislikeAIPerformance = async () => {
    if (currentLikeState === likeState.Dislike) {
      setCurrentLikeState(likeState.None);
      mutate({ inputLogId: logId, inputCurrentLikeState: likeState.None });
    } else {
      setCurrentLikeState(likeState.Dislike);
      mutate({ inputLogId: logId, inputCurrentLikeState: likeState.Dislike });
    }
  };

  return logId ? (
    <HStack width='100%' justifyContent='right'>
      <Tooltip label={'I like this AI generated response'} fontSize={'sm'}>
        <IconButton
          bg='transparent'
          onClick={likeAIPerformance}
          borderRadius={'4px'}
          size={'sm'}
          icon={<LikeIcon fill={currentLikeState === likeState.Like ? 'primaryLight' : 'background'} />}
          aria-label='like-icon'
          _hover={{ bg: 'green4' }}
        />
      </Tooltip>
      <Tooltip label={"I don't like this AI generated response"} fontSize={'sm'}>
        <IconButton
          bg='transparent'
          onClick={dislikeAIPerformance}
          borderRadius={'4px'}
          size={'sm'}
          icon={<DislikeIcon fill={currentLikeState === likeState.Dislike ? 'primaryLight' : 'background'} />}
          aria-label='dislike-icon'
          _hover={{ bg: 'green4' }}
        />
      </Tooltip>
    </HStack>
  ) : (
    ''
  );
};

export default AIPerformanceLog;
