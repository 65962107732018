import { atom } from 'jotai';
import { EnvId } from 'Models/config';
import { User } from 'Models/user';

const initialUserState: User = {
  id: 0,
  email: '',
  firstname: '',
  lastname: '',
  loggedIn: false,
  activities_suggestion: null,
  classrank: null,
  country: null,
  created_at: '',
  email_verified: false,
  ethnicity: null,
  extracted_interests: null,
  extracted_major: null,
  first_time_login: false,
  forgot_password_token: null,
  gender: null,
  gpa: null,
  gradyear: null,
  highschool: null,
  income: null,
  intake_year: null,
  phone_number: null,
  premium_user: false,
  role: 'user',
  satenglish: null,
  satmath: null,
  standardized_tests: false,
  act: null,
  state: null,
  stripe_customer_id: null,
  updated_at: '',
  referral_code: '',
};

export const userAtom = atom<User>(initialUserState);

export const resetUserAtom = atom(null, (_, set) => {
  set(userAtom, initialUserState);
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  userAtom.debugLabel = 'user';
}

export const userIdAtom = atom((get) => {
  const user = get(userAtom);
  return user?.id ? user?.id : null;
});

export const userFirstNameAtom = atom((get) => {
  const user = get(userAtom);
  return user?.firstname;
});

export const userLastNameAtom = atom((get) => {
  const user = get(userAtom);
  return user?.lastname;
});

export const userFullNameAtom = atom((get) => {
  const user = get(userAtom);
  return user?.firstname + ' ' + user?.lastname;
});

export const userReferralCodeAtom = atom((get) => {
  const user = get(userAtom);
  return user?.referral_code;
});

export const isFirstTimeUser = atom((get) => {
  const user = get(userAtom);
  return user?.first_time_login;
});
