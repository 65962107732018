
import React, {Suspense} from "react";
import Loading from "../lib/UI-Components/Loading";


const LazyComponent = (Component) => (props) => (
  <Suspense fallback={<Loading/>}>
    <Component {...props} />
  </Suspense>
);

export default LazyComponent;
