import { atom } from 'jotai';
import { Routes } from '~/Constants/constants';

interface IBanner {
  name: string;
  isVisible: boolean;
  message: string;
  screen: Routes | null;
}

export const bannerAtom = atom<IBanner>({
  name: '',
  isVisible: false,
  screen: null,
  message: '',
});
