import { IReviewOptions } from 'types/essays';

export enum ESSAY_FEATURES_TAB {
  EssayReview = 'essayReview',
  EssayPlan = 'essayPlan',
  AskKollegio = 'askKollegio',
  Edit = 'Edit',
  Prompt = 'Prompt',
  Lengthen = 'lengthen',
  Shorten = 'shorten',
  Reword = 'reword',
  Transition = 'transition',
  MergeIdea = 'integrate',
  Suggestion = 'suggestion',
}
export enum ESSAY_STATUS {
  NOT_STARTED_YET = 'NOT_STARTED_YET',
  DRAFT_1_COMPLETED = 'DRAFT_1_COMPLETED',
  DRAFT_2_COMPLETED = 'DRAFT_2_COMPLETED',
  COMPLETED = 'COMPLETED',
}

export const ESSAY_DRAFT_OPTIONS = [
  { value: ESSAY_STATUS.NOT_STARTED_YET, label: 'Draft 1', bgColor: 'gray14' },
  { value: ESSAY_STATUS.DRAFT_1_COMPLETED, label: 'Draft 2', bgColor: 'gray14' },
  { value: ESSAY_STATUS.DRAFT_2_COMPLETED, label: 'Finalizing', bgColor: 'gray14' },
  { value: ESSAY_STATUS.COMPLETED, label: 'Completed', bgColor: 'success' },
];

export const ESSAY_REVIEW_OPTIONS: IReviewOptions[] = [
  { type: 'overallReview', url: 'overallReview', label: 'Overall review' },
  {
    type: 'sentenceReview',
    label: 'Sentence by sentence review',
    url: 'sentenceReview',
  },
  {
    type: 'score',
    label: 'Both',
    url: 'score',
  },
  { type: 'partial', url: 'score', label: 'Partial review' },
];

export const AUTO_SAVE_DELAY = 1000;

const DEFAULT_CHAR_LIMIT = 30000;
const DEFAULT_CHAR_WARNING = 29999;

export const EDITOR_LIMITS = {
  CHAR_LIMIT: DEFAULT_CHAR_LIMIT,
  CHAR_WARNING: DEFAULT_CHAR_WARNING,
};

export enum AI_TOOL_NAME {
  Lengthen = 'lengthen',
  Shorten = 'shorten',
  Reword = 'reword',
  Transition = 'transition',
  Merge = 'integrate',
  Suggestion = 'suggestion',
}

export const AI_TOOL_LABELS = {
  [AI_TOOL_NAME.Lengthen]: 'Lengthen',
  [AI_TOOL_NAME.Shorten]: 'Shorten',
  [AI_TOOL_NAME.Reword]: 'Reword',
  [AI_TOOL_NAME.Transition]: 'Transition',
  [AI_TOOL_NAME.Merge]: 'Merge Idea',
  [AI_TOOL_NAME.Suggestion]: 'Suggestion',
};

export enum ESSAY_FEATURES_TAB_LABEL {
  EssayReview = 'Review',
  EssayPlan = 'Plan',
  AskKollegio = 'Ask Kollegio',
  Edit = 'Edit',
  Prompt = 'Prompt',
  lengthen = 'Lengthen',
  shorten = 'Shorten',
  reword = 'Reword',
  transition = 'Transition',
  integrate = 'Merge Idea',
  suggestion = 'Suggestions',
}

export const AI_WRITING_TOOLS = [
  ESSAY_FEATURES_TAB.Lengthen,
  ESSAY_FEATURES_TAB.Shorten,
  ESSAY_FEATURES_TAB.Reword,
  ESSAY_FEATURES_TAB.Transition,
  ESSAY_FEATURES_TAB.MergeIdea,
  ESSAY_FEATURES_TAB.Suggestion,
];
export const AI_TOOL_MIN_WORD_LENGTH = {
  [AI_TOOL_NAME.Lengthen]: 5,
  [AI_TOOL_NAME.Shorten]: 10,
  [AI_TOOL_NAME.Reword]: 10,
  [AI_TOOL_NAME.Transition]: 15,
  [AI_TOOL_NAME.Merge]: 10,
  [AI_TOOL_NAME.Suggestion]: 5,
};

export const DEFAULT_AI_TOOL_TIPS = {
  [AI_TOOL_NAME.Lengthen]: 'Highlight a sentence or paragraph you would like to lengthen',
  [AI_TOOL_NAME.Shorten]: 'Highlight a sentence or paragraph you would like to shorten',
  [AI_TOOL_NAME.Reword]: 'Highlight a sentence or paragraph you would like to reword',
  [AI_TOOL_NAME.Transition]: 'Highlight a sentence or paragraph that you would like to integrate an idea into',
  [AI_TOOL_NAME.Merge]: 'Highlight ideas you would like to merge',
  [AI_TOOL_NAME.Suggestion]: 'Highlight a sentence or paragraph that you would like next step suggestions for',
};

export const DISABLED_AI_TOOL_TIPS = {
  [AI_TOOL_NAME.Lengthen]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Lengthen]} words`,
  [AI_TOOL_NAME.Shorten]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Shorten]} words`,
  [AI_TOOL_NAME.Reword]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Reword]} words`,
  [AI_TOOL_NAME.Transition]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Transition]} words`,
  [AI_TOOL_NAME.Merge]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Merge]} words`,
  [AI_TOOL_NAME.Suggestion]: `Please select at least ${AI_TOOL_MIN_WORD_LENGTH[AI_TOOL_NAME.Suggestion]} words`,
};

export const REWORD_TOOL_STYLES = ['Default', 'Academic', 'Casual', 'Serious'];

export const DEFAULT_HIGHLIGHT_COLOR = 'white';

export const ESSAY_DEMO = {
  brainstormTitle: 'Brainstormer set up',
  brainstormContent: `Creatively brainstorm ideas based on your
   profile and generate detailed plans on how to build your best possible essay.`,
};

export const RESPONSIVE_BREAKPOINT = '992px';

export enum MESSAGES_TYPE {
  USER = 'user',
  BOT = 'bot',
}

export const ASK_KOLLEGIO_FIRST_MESSAGE = {
  id: 0,
  content: `Hi, I’m your writing partner and editor. I can see your 
  current draft, brainstorm ideas, give feedback, and more.\nHow can I help with your writing today?`,
  timestamp: new Date(),
  role: MESSAGES_TYPE.BOT,
  isLoading: false,
};
