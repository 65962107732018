import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { UserCollege } from '../../Models/colleges';
import CustomModal from '../../lib/UI-Components/Modal';
import { ModalProps } from '../Modals/WritingToolsModal';
import Report from './Report';

interface ReportModalProps extends ModalProps {
  college?: UserCollege | null;
}

const ReportModal = ({ isOpen, onClose }: ReportModalProps) => (
  <>
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      size={['xs', 'md', 'lg']}
      backdropFilter='blur(5px)'
      header={
        <HStack justifyContent={'space-between'}>
          <Text color={'darkBlue'} fontSize={['md', 'xl']} fontWeight={'semibold'}>
            Report a Problem
          </Text>
        </HStack>
      }
      body={<Report onClose={onClose} />}
    />
  </>
);

export default ReportModal;
