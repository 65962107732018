import { atom } from 'jotai';
import { INotes } from '../types/notes';

export const notesAtom = atom<INotes>({
  isOpen: false,
  placement: 'right',
  notes: [],
  isCreateNoteView: false,
  textFieldContent: '',
  isEditNoteView: false,
  selectedNoteId: null,
  notesOrder: [],
});
