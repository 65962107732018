import { useEffect } from 'react';

const usePixel = () => {
  const pixelId = process.env.REACT_APP_PIXEL_ID;

  useEffect(() => {
    if (pixelId) {
      const script = document.createElement('script');
      script.innerHTML = `
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');
        fbq('track', 'PageView');
      `;
      document.body.appendChild(script);
    } else {
      console.error('Pixel ID is missing from environment variables');
    }
  }, [pixelId]);

  const trackCustomEvent = (eventName: string, eventData: object) => {
    // @ts-ignore
    if (typeof window !== 'undefined' && window?.fbq) {
      // @ts-ignore
      window?.fbq('trackCustom', eventName, eventData);
    }
  };

  return { trackCustomEvent };
};

export default usePixel;
