import { atom } from 'jotai';

type AlertType = 'info' | 'warning' | 'success' | 'error' | 'loading';

interface Alert {
    isVisible: boolean;
    type: AlertType;
    message: string;
}

export const alertAtom = atom<Alert>({
  isVisible: false,
  type: 'success',
  message: '',
});

