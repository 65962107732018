import { Box, Image, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

interface PlaceholderImageProps {
  imageSrc?: string;
  placeholderHeight?: string | string[] | { base: string; md: string };
  imageWidth?: string | string[] | { base: string; md: string };
  imageHeight?: string | string[] | { base: string; md: string };
  alt?: string;
  boxSize?: string | string[];
  loaderSize?: string;
  borderRadius?: string | string[] | { base: string; md: string };
}

const PlaceholderImage = ({
  imageSrc,
  placeholderHeight = '20vh',
  imageWidth = 'full',
  imageHeight,
  boxSize,
  alt,
  borderRadius = 'none',
  loaderSize = 'lg',
}: PlaceholderImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [imageSrc]);

  const renderImage = () => (
    <Box
      w={'full'}
      h={imageLoaded ? 'auto' : placeholderHeight}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {!imageLoaded && <Spinner size={loaderSize} position='absolute' />}
      <Image
        alt={alt}
        src={imageSrc}
        width={imageWidth}
        height={imageHeight}
        objectFit='cover'
        boxSize={boxSize}
        onLoad={() => setImageLoaded(true)}
        visibility={imageLoaded ? 'visible' : 'hidden'}
        borderRadius={borderRadius}
      />
    </Box>
  );

  return <>{renderImage()}</>;
};

export default PlaceholderImage;
