import { atom } from 'jotai';

export interface IIsCreateNewEssayModalOpenObject {
  id: number;
  college_id: number;
  prompt: string;
  wordcount: string;
  isCreateNewEssayModalOpen?: boolean;
}
export const isAccordionOpenAtom = atom(false);

export const isCreateNewEssayModalOpenObject = atom<IIsCreateNewEssayModalOpenObject>({
  id: 0,
  college_id: 1,
  prompt: `Some students have a background, 
  identity, interest, or talent that is so meaningful they believe their application
   would be incomplete without it. If this sounds like you, then please share your story.`,
  wordcount: '250 - 650',
  isCreateNewEssayModalOpen: false,
});
