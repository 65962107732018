import { Button, Card, HStack, Text, VStack, chakra } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore
import { useAtom } from 'jotai';
import { notesAtom } from '../../Atoms/notesAtom';
import { useDeleteNote } from '../../Hooks/Notes/useNotes';
import { Note } from '../../Models/notes';
import { ReactComponent as _DeleteIcon } from './../../Assets/icons/delete.svg';
const DeleteIcon = chakra(_DeleteIcon);

interface INotesCardProps {
  note: Note;
}

const NotesCard: React.FC<INotesCardProps> = (note) => {
  const [notes, setNotes] = useAtom(notesAtom);
  const { deleteNote, isPending } = useDeleteNote();
  return (
    <Card
      backgroundColor={'background'}
      padding={4}
      width={'100%'}
      boxShadow={'none'}
      border='1px solid white'
      onClick={() => {
        setNotes({
          ...notes,
          selectedNoteId: note?.note?.id,
          isEditNoteView: true,
          textFieldContent: note?.note?.content,
        });
      }}
      _hover={{
        backgroundColor: 'lightGreen1',
        border: '1px solid #3E8A75',
      }}
    >
      <VStack width={'100%'}>
        <Text color='gray4' noOfLines={3} width={'100%'} textAlign={'left'}>
          {note?.note?.content}
        </Text>
        <HStack width={'100%'} justifyContent={'space-between'}>
          <Text backgroundColor={'foreground'} color={'gray4'} padding={1} fontSize={'sm'} borderRadius={'xl'}>
            {new Date(note?.note?.updated_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Text>
          <Button
            isDisabled={isPending}
            onClick={(e) => {
              e.stopPropagation();
              deleteNote(note?.note?.id);
            }}
            zIndex={10}
            size='sm'
            background={'foreground'}
            borderRadius={'full'}
            _hover={{
              backgroundColor: 'foreground',
            }}
          >
            <DeleteIcon
              color={'#F97066'}
              _hover={{
                color: 'red',
              }}
            />
          </Button>
        </HStack>
      </VStack>
    </Card>
  );
};

export default NotesCard;
