import { useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { alertAtom } from '../../Atoms/alertAtom';
import { ALERT_TYPES } from '../../Constants/constants';

export const AlertWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const toast = useToast();
  const [alert, setAlert] = useAtom(alertAtom);

  useEffect(() => {
    if (alert.isVisible) {
      toast({
        description: alert.message,
        status: alert.type,
        position: alert.type === ALERT_TYPES.INFO ? 'top-left' : 'bottom-right',
        duration: 3000,
        variant: 'subtle',
        isClosable: alert.type === ALERT_TYPES.INFO,
      });

      const timer = setTimeout(() => {
        setAlert({ ...alert, isVisible: false });
      }, 2000);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [alert, setAlert, toast]);

  return <>{children}</>;
};
