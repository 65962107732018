import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userAtom } from '../Atoms/App/user';
import { isPublicRoute } from '../Utils/auth';
import Loading from '../lib/UI-Components/Loading';

const useAuth = () => {
  const user = useAtomValue(userAtom);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuth = () => {
      if (user) {
        setIsAuth(user?.loggedIn && user?.email_verified);
      }

      setIsLoading(false);
    };

    checkAuth();
  }, [user]);

  return { isAuth, isLoading };
};

const PrivateRoutes: React.FC = () => {
  const location = useLocation();
  const { isAuth, isLoading } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (isAuth) {
    if (isPublicRoute(location.pathname)) {
      return <Navigate to='/home' />;
    }

    return <Outlet />;
  }

  if (!isAuth && !isPublicRoute(location.pathname)) {
    return <Navigate to='/login' />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
