import { Card, Flex, Image, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react'; // @ts-ignore
import KollegioRobot from './../../Assets/images/kollegio-robot.svg';

interface INoMessagesViewProps {
  handleSendMessage: (value: string, type: string) => void;
}

const NoMessagesView: React.FC<INoMessagesViewProps> = ({ handleSendMessage }) => {
  const questions = ['Where should I start?', 'What is Kollegio?', 'How do I pick colleges?'];

  const subHeadingText = 'Ask me anything about kollegio and college admissions';

  const questionsCard = () =>
    questions?.map((question, index) => (
      <Card
        key={`chatbot-question-${index}`}
        width={'fit-content'}
        paddingY={'8px'}
        paddingX={'12px'}
        border={'0.883px solid'}
        cursor={'pointer'}
        noOfLines={1}
        whiteSpace={'nowrap'}
        _hover={{
          backgroundColor: 'gray1',
        }}
        borderColor={'primary'}
        onClick={() => handleSendMessage(question, 'Pre-defined message')}
      >
        <Text textAlign={'left'} fontSize='xs' width={'100%'} color={'primary'} fontWeight={600}>
          {question}
        </Text>
      </Card>
    ));

  return (
    <VStack spacing={6} width={'100%'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
      <VStack width={'100%'} paddingTop={4} paddingX={4} spacing={4} alignItems={'center'}>
        <Image src={KollegioRobot} />
        <Text color='#667085' fontSize={'sm'} textAlign={'center'}>
          {subHeadingText}
        </Text>
      </VStack>
      <Stack
        width={'100%'}
        flexDirection={'column'}
        alignItems={'flex-end'}
        whiteSpace={'nowrap'}
        overflowX={'auto'}
        paddingY={0}
        css={{
          '&::-webkit-scrollbar': {
            height: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'white',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'white',
          },
          '&::-webkit-scrollbar-thumb:active': {
            background: 'white',
          },
          '&::-webkit-scrollbar-track': {
            background: 'white',
          },
          '&::-webkit-scrollbar-track:hover': {
            background: 'white',
          },
        }}
      >
        <Flex flexDirection={'column'} alignItems={'flex-end'} gap={4}>
          {questionsCard()}
        </Flex>
      </Stack>
    </VStack>
  );
};

export default NoMessagesView;
