import { Box, BoxProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react';
import React from 'react';

interface LoadingProps extends SpinnerProps {
  message?: string;
  boxProps?: BoxProps;
  props?: any;
}

const Loading = ({ message, boxProps, ...props }: LoadingProps) => (
  <Box display='flex' flexDirection={'column'} justifyContent='center' alignItems='center' height='60vh' {...boxProps}>
    <Spinner color='slategreen' emptyColor='gray2' size='xl' thickness='4px' {...props} />
    {message ? (
      <Text mt={4} color={'gray6'} align={'center'}>
        {message}
      </Text>
    ) : null}
  </Box>
);

export default Loading;
