import { useEffect } from 'react';

const useSessionTracker = () => {
  const incrementSessionCount = () => {
    const sessionCount = parseInt(localStorage.getItem('sessionCount') || '0', 10);
    localStorage.setItem('sessionCount', (sessionCount + 1).toString());
  };

  useEffect(() => {
    const sessionStarted = sessionStorage.getItem('sessionStarted');

    if (!sessionStarted) {
      incrementSessionCount();
      sessionStorage.setItem('sessionStarted', 'true');
    }
  }, []);
  return { incrementSessionCount };
};

export default useSessionTracker;
