import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  size?: any;
  backdropFilter?: any;
  overlayProps?: any;
  contentProps?: any;
  footerProps?: any;
  bodyProps?: any;
  headerProps?: any;
  className?: string;
  modalOverlay?: boolean;
  closeOnOverlayClick?: boolean;
}

export const CustomModal = ({
  isOpen,
  onClose,
  header,
  body,
  footer,
  size,
  backdropFilter = 'blur(5px)',
  overlayProps,
  contentProps,
  footerProps,
  bodyProps,
  headerProps,
  className = 'custom-modal',
  modalOverlay = true,
  closeOnOverlayClick = true,
  ...props
}: ModalProps) => (
  <ChakraModal
    variant='responsive'
    closeOnOverlayClick={closeOnOverlayClick}
    isOpen={isOpen}
    onClose={onClose}
    size={size}
    trapFocus={false}
    motionPreset='scale'
    {...props}
  >
    {modalOverlay && <ModalOverlay backdropFilter={backdropFilter} {...overlayProps} />}
    <div className={className}>
      <ModalContent bg='foreground' borderRadius={'3xl'} alignSelf={'center'} {...contentProps}>
        {header && <ModalHeader {...headerProps}>{header}</ModalHeader>}
        <ModalCloseButton color={'gray5'} borderRadius={'50%'} bg={'gray1'} _hover={{ bg: 'blackAlpha.100' }} />
        <ModalBody {...bodyProps}>{body}</ModalBody>
        {footer && <ModalFooter {...footerProps}>{footer}</ModalFooter>}
      </ModalContent>
    </div>
  </ChakraModal>
);

export default CustomModal;
