import { useField, useFormikContext } from "formik";
import { Input } from "@chakra-ui/react";
import React from "react";

// interface FormattedInputProps extends InputProps{
//   name: string;
// }

const FormattedInput = ({ ...props }: any) => {
  const [field] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleBlur = (event: any) => {
    const inputValue = event.target.value;

    if (!Number.isNaN(+inputValue)) {
      const roundedValue = Math.round(+inputValue * 100) / 100;
      setFieldValue(field.name, roundedValue.toString());
    }

    // Optionally: Call Formik's field onBlur handler to handle validation, etc.
    field.onBlur(event);
  };

  return <Input {...field} {...props} onBlur={handleBlur} />;
};

export default FormattedInput;
