import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { userAtom, userFullNameAtom } from '../../Atoms/App/user';
import { alertAtom } from '../../Atoms/alertAtom';
import { ALERT_TYPES } from '../../Constants/constants';
import reportService from '../../Services/report';

export const useReportIssue = () => {
  const user = useAtomValue(userAtom);
  const userName = useAtomValue(userFullNameAtom);
  const setAlert = useSetAtom(alertAtom);

  const { mutate: reportProblem, isPending } = useMutation({
    mutationFn: async ({ description, type }: any) =>
      reportService.reportProblem({
        email: user?.email,
        name: userName,
        location: window.location.href,
        description,
        type,
      }),
    onSuccess: () => {
      setAlert({ isVisible: true, message: 'Your problem has been successfully reported', type: ALERT_TYPES.SUCCESS });
    },
    onError: ({ error }: any) => {
      setAlert({
        isVisible: true,
        message: error ? error : 'An unexpected error occurred while submitting your report.',
        type: ALERT_TYPES.ERROR,
      });
    },
  });

  return { reportProblem, isPending };
};
