import metadata from 'libphonenumber-js/metadata.full.json';
import { LogOutCallback } from 'types/auth';
import { PublicRoutes } from '../Constants/constants';
import { getQueryParams } from './app';

export const getGoogleOAuthURL = (signup = false, referrer: string | null = null) => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const redirectUri = signup ? `${process.env.REACT_APP_URL}/join` : `${process.env.REACT_APP_URL}/login`;

  // Get Query parameters from the current URL
  const { utmParams, referralParams, otherParams, variant } = getQueryParams();

  const state = encodeURIComponent(JSON.stringify({ referrer, utmParams, referralParams, otherParams, variant }));

  const options: any = {
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    state, // Use the encoded state here
    scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(
      ' '
    ),
  };

  const qs = new URLSearchParams(options);
  // eslint-disable-next-line no-restricted-syntax
  console.log(`here: ${rootUrl}?${qs.toString()}`);
  return `${rootUrl}?${qs.toString()}`;
};

type CountryCode = keyof typeof metadata.countries;
type CountryData = (typeof metadata.countries)[CountryCode];

type CountryDataMap = Record<string, CountryData>;

export const getDialCodes = () => {
  const countries = metadata.countries as CountryDataMap;
  const dialCodesWithCountry: { [key: string]: string } = {};

  const dialCodesArray = Object.keys(countries)
    ?.map((country) => {
      const countryData = countries[country];

      if (!countryData) {
        return null;
      }

      dialCodesWithCountry[`+${countryData[0]}`] = country;

      return {
        [`+${countryData[0]}`]: `+${countryData[0]}`,
      };
    })
    .filter((item): item is { [key: string]: string } => item !== null)
    .map((item) => Object.entries(item)[0]) // Convert to [key, value] array
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by key (dial code)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: string });

  return { dialCodes: dialCodesArray, dialCodesWithCountry };
};

export const isPublicRoute = (pathname: string) => PublicRoutes.includes(pathname as never);

let logOutCallback: LogOutCallback | null = null;
let sessionCallback: LogOutCallback | null = null;

export const setLogOutCallback = (callback: LogOutCallback | null, sessionTrackerCallback: LogOutCallback | null) => {
  logOutCallback = callback;
  sessionCallback = sessionTrackerCallback;
};

export const triggerLogOut = () => {
  if (logOutCallback) {
    logOutCallback();
  }
};

export const triggerSessionCount = () => {
  if (sessionCallback) {
    sessionCallback();
  }
};
