import { useAtom } from 'jotai';
import React, { lazy } from 'react';
import Joyride from 'react-joyride';
import { Route, Routes } from 'react-router-dom';
import { demoAtom } from '../Atoms/demoAtom';
import useJoyRide from '../Hooks/App/useJoyRide';
import { demoStyles } from '../Utils/common';
import DemoTooltip from '../lib/UI-Components/Demo/DemoTooltip';
import LazyComponent from './LazyComponent';
import PrivateRoutes from './PrivateRoutes';

// const Questionnaire = LazyComponent(
//   React.lazy(() => import("../Components/Questionnaire/Questionnaire"))
// );

// const GetStarted = LazyComponent(
//   React.lazy(() => import("../Components/Questionnaire/GetStartedPage"))
// );

const Activities = LazyComponent(lazy(() => import('../Components/Activities/Activities')));

const ActivityUploadReview = LazyComponent(
  lazy(() => import('../Components/Activities/UploadActivity/ReviewActivities/ReviewInfo'))
);

const AddActivity = LazyComponent(lazy(() => import('../Components/Activities/EditAddActivity/AddActivity')));
const CollegeDetails = LazyComponent(lazy(() => import('../Components/CollegeDetails/CollegeDetails')));
const Colleges = LazyComponent(lazy(() => import('../Components/Colleges/Colleges')));
const ChanceMe = LazyComponent(lazy(() => import('../Components/ChanceMe/ChanceMe')));
const Dashboard = LazyComponent(lazy(() => import('../Components/Dashboard/Dashboard')));
const EditActivity = LazyComponent(lazy(() => import('../Components/Activities/EditAddActivity/EditActivity')));
const EmailVerified = LazyComponent(lazy(() => import('../Components/Verify/EmailVerified')));
const Essay = LazyComponent(lazy(() => import('../Components/Essay/Essay')));
const EssayWriter = LazyComponent(lazy(() => import('../Components/EssayWriter/EssayWriter')));
const EssayReviewer = LazyComponent(lazy(() => import('../Components/EssayWriter/EssayReviewer')));

const ExpandedResults = LazyComponent(
  lazy(() => import('../Components/Colleges/Recommendations/Results/ExpandedResults'))
);

const ForgotPassword = LazyComponent(lazy(() => import('../Components/Forgot/ForgotPassword')));
const Planner = LazyComponent(lazy(() => import('../Components/Planner/Planner')));
const Profile = LazyComponent(lazy(() => import('../Components/Profile/Profile')));
const Questions = LazyComponent(lazy(() => import('../Components/FirstTimeUX/Questions')));
const Recommendations = LazyComponent(lazy(() => import('../Components/Colleges/Recommendations/Recommendations')));

const RecommendationResults = LazyComponent(
  lazy(() => import('../Components/Colleges/Recommendations/Results/RecommendationResults'))
);

const Report = LazyComponent(lazy(() => import('../Components/Report/Report')));
const ResetPassword = LazyComponent(lazy(() => import('../Components/Forgot/ResetPassword')));
const Resources = LazyComponent(lazy(() => import('../Components/Resources/Resources')));
const SignIn = LazyComponent(lazy(() => import('../Components/Login/SignIn')));
const SignUp = LazyComponent(lazy(() => import('../Components/Login/SignUp')));
const VerifyEmail = LazyComponent(lazy(() => import('../Components/Verify/VerifyEmail')));

const SearchSpecificUniversity = LazyComponent(
  lazy(() => import('../Components/ChanceMe/SpecificUniversity/SearchUniveristy'))
);

const AllUniversity = LazyComponent(lazy(() => import('../Components/ChanceMe/AllUniversities/AllUniversities')));

const SingleUniversity = LazyComponent(
  lazy(() => import('../Components/ChanceMe/SpecificUniversity/SingleUniversity'))
);

const RoutesComponent = () => {
  const [demo] = useAtom(demoAtom);
  const { handleCallback } = useJoyRide();
  return (
    <>
      <Joyride
        run={demo?.showJoyride}
        steps={demo?.steps}
        stepIndex={demo?.isControlled ? demo?.index : undefined}
        continuous={!demo?.isControlled}
        styles={demoStyles}
        tooltipComponent={DemoTooltip}
        callback={(data) => handleCallback(data)}
      />
      <Routes>
        <Route path='/join' element={<SignUp />} />
        <Route path='/verify' element={<VerifyEmail />} />
        <Route path='/verify/:token' element={<EmailVerified />} />
        <Route path='/forgot' element={<ForgotPassword />} />
        <Route path='/reset/:token' element={<ResetPassword />} />
        <Route path='/essays/view/:id' element={<EssayReviewer />} />
        <Route
          element={
            <>
              <PrivateRoutes />
            </>
          }
        >
          <Route path='/' element={<SignIn />} />
          {/* <Route path="/questionnaire" element={<Questionnaire />} /> */}
          <Route path='/login' element={<SignIn />} />
          <Route path='/intro' element={<Questions />} />
          <Route path='/home' element={<Dashboard />} />
          <Route path='/activities' element={<Activities />} />
          <Route path='/activities/add' element={<AddActivity />} />
          <Route path='/activities/edit/:id' element={<EditActivity />} />
          <Route path='/activities/upload' element={<ActivityUploadReview />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/colleges' element={<Colleges />} />
          <Route path='/college/:id' element={<CollegeDetails />} />
          <Route path='/planner' element={<Planner />} />
          <Route path='/report' element={<Report />} />
          <Route path='/essays' element={<Essay />} />
          <Route path='/essays/edit/:id' element={<EssayWriter />} />

          <Route path='/resources' element={<Resources />} />
          <Route path='/recommender' element={<Recommendations />} />
          <Route path='/recommendations' element={<RecommendationResults />} />
          <Route path='/recommendations/:id' element={<ExpandedResults />} />
          <Route path='/colleges/chance-me/search' element={<SearchSpecificUniversity />} />
          <Route path='/colleges/chance-me/all' element={<AllUniversity />} />
          <Route path='/colleges/chance-me/:id' element={<SingleUniversity />} />
          <Route path='/colleges/chance-me' element={<ChanceMe />} />
        </Route>

        <Route path='*' element={<SignIn />} />
      </Routes>
    </>
  );
};

export default RoutesComponent;
