import { ColorModeScript } from '@chakra-ui/color-mode';
import { ChakraProvider } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import AppRouter from './AppRouter'; // New router file
import theme from './theme';

axios.defaults.withCredentials = true;

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <RouterProvider router={AppRouter} />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
