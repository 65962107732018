import { AbsoluteCenter, Box, Image } from '@chakra-ui/react'; // @ts-ignore
import Chat from 'Assets/images/chat-bot.svg';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import Chatbox from './ChatBox';

const ChatBotButton = () => {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const location = useLocation();
  const { trackEvent } = useMixpanel();

  useEffect(() => {
    if (isChatboxOpen) {
      // disable background scroll
      document.body.style.overflow = 'hidden';
    } else {
      // re-enable background scroll
      document.body.style.overflow = '';
    }

    // cleanup
    return () => {
      document.body.style.overflow = '';
    };
  }, [isChatboxOpen]);

  const closeChatBox = () => {
    trackEvent('Chat Bot Closed using close button', { screen: location?.pathname });
    setIsChatboxOpen(false);
  };

  const toggleChatbox = () => {
    trackEvent(isChatboxOpen ? 'Chat Bot Closed' : 'Chat Bot Opened', { screen: location?.pathname });
    setIsChatboxOpen(!isChatboxOpen);
  };

  return (
    <>
      {isChatboxOpen && <Chatbox handleClickOnCross={closeChatBox} />}
      <Box
        position='fixed'
        bottom={['20px', '40px']}
        right={['20px', '40px']}
        width='60px'
        height='60px'
        borderRadius='50%'
        bgGradient='linear-gradient(147deg, #035A41 -5.79%, #3E8A75 104.73%)'
        boxShadow='lg'
        cursor='pointer'
        zIndex='1000'
        onClick={toggleChatbox}
      >
        <AbsoluteCenter>
          <Image src={Chat} alt='chat-bot' boxSize='30px' />
        </AbsoluteCenter>
      </Box>
    </>
  );
};

export default ChatBotButton;
