import React from "react";
import { VStack, Tooltip, IconButton } from "@chakra-ui/react";

interface ProfileAndAddonsSectionProps {
  tooltipContent: any;
  onClick?: any;
  icon: any;
  label?: any;
  display?: any;
}

const AddonButton = ({
  tooltipContent,
  onClick,
  icon,
  label,
  display,
}: ProfileAndAddonsSectionProps) => (
  <Tooltip label={tooltipContent} placement="bottom" width={"90%"}>
    <VStack
      bg="background"
      spacing={0}
      px={[0, 0, 2, 2, 2]}
      py={1}
      borderRadius={"md"}
      onClick={onClick}
      color={"gray5"}
      _hover={{
        cursor: "pointer",
        color: "primaryLight",
        "& > button": { color: "primaryLight" },
      }}
      display={display}
    >
      <IconButton
        _hover={{ cursor: "pointer" }}
        color={"gray5"}
        bg="background"
        boxSize={6}
        alignItems={"center"}
        aria-label="Notes"
        onFocus={(e) => e.preventDefault()}
        icon={icon}
      />
      {label}
    </VStack>
  </Tooltip>
);

export default AddonButton;
