import { Box, IconButton, InputGroup, InputRightElement, Textarea, VStack, chakra } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { userAtom } from '../../Atoms/App/user.ts';
import { MESSAGES_TYPE } from '../../Constants/chatbots';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel.tsx'; // @ts-ignore
import { ReactComponent as _SendIcon } from './../../Assets/icons/send-arrow-icon.svg';
import { useSendMessage } from './../../Hooks/Chatbot/useChatbot.ts';
import MessageBox from './MessageBox';
import NoMessagesView from './NoMessagesView';

const SendIcon = chakra(_SendIcon);

interface Message {
  id: number;
  content: string;
  role: MESSAGES_TYPE;
  timestamp: Date;
  isLoading: boolean;
  logId?: string;
}

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<number>(1);
  const { trackEvent } = useMixpanel();

  const [inputValue, setInputValue] = useState<string>('');
  const user = useAtomValue(userAtom);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { mutate } = useSendMessage(setMessages, setIsLoading);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const lineBreaks = (event?.target?.value?.match(/\n/g) || [])?.length + 1;

    if (event?.target?.value?.length > 35 || lineBreaks > 1) {
      setRows(2);
    } else if (event?.target?.value?.length < 35 || lineBreaks <= 1) {
      setRows(1);
    }

    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(inputValue, 'Free Text');
    }
  };

  const handleSendMessage = (value: string, type: string) => {
    const trimmedMessage = value?.trim();

    if (trimmedMessage) {
      const updatedMessages = [
        ...messages,
        {
          id: messages?.length,
          content: trimmedMessage,
          role: MESSAGES_TYPE.USER,
          timestamp: new Date(),
          isLoading: false,
        },
      ];

      setIsLoading(true);
      trackEvent('User Sent Message To Chatbot', { message: trimmedMessage, type });
      mutate({ payload: { conversation: updatedMessages, user_id: user?.id } });
      setMessages((prev) => [
        ...prev,
        {
          id: prev?.length,
          content: trimmedMessage,
          role: MESSAGES_TYPE.USER,
          timestamp: new Date(),
          isLoading: false,
        },
        {
          id: prev?.length + 1,
          content: '',
          role: MESSAGES_TYPE.BOT,
          timestamp: new Date(),
          isLoading: true,
        },
      ]);
      setInputValue('');
      setRows(1);
    }
  };

  return (
    <VStack width={'100%'} height={'100%'} justifyContent={'space-between'} overflow={'hidden'} padding={2}>
      {messages?.length === 0 ? (
        <NoMessagesView handleSendMessage={handleSendMessage} />
      ) : (
        <VStack
          width={'100%'}
          overflowY={'auto'}
          spacing={4}
          css={{
            '&::-webkit-scrollbar': {
              width: '1px',
              height: '1px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'white',
              borderRadius: '1px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'white',
            },
            '&::-webkit-scrollbar-thumb:active': {
              background: 'white',
            },
            '&::-webkit-scrollbar-track': {
              background: 'white',
            },
            '&::-webkit-scrollbar-track:hover': {
              background: 'white',
            },
          }}
        >
          {messages?.map((message, index) => (
            <MessageBox
              key={`chat-bot-message-${index}`}
              message={message?.content}
              timestamp={message?.timestamp}
              type={message?.role}
              isLoading={message?.isLoading}
              logId={message.logId}
            />
          ))}
          <div ref={messagesEndRef} />
        </VStack>
      )}
      <Box width={'100%'}>
        <InputGroup>
          <Textarea
            borderRadius={'lg'}
            background='gray1'
            border='1px solid #D0D4DB'
            value={inputValue}
            fontSize={'sm'}
            color={'gray5'}
            focusBorderColor='primary'
            placeholder='Start Typing...'
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            rows={rows}
            paddingBottom={3}
            paddingRight={'2rem'}
          />
          <InputRightElement>
            <IconButton
              onClick={() => handleSendMessage(inputValue, 'Free Text')}
              borderRadius={'4px'}
              size={'sm'}
              _hover={{ background: 'primaryLight' }}
              isDisabled={isLoading}
              aria-label='send-icon'
              icon={<SendIcon />}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
    </VStack>
  );
};

export default Chat;
