import axios from 'axios';

class ChatBot {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async sendMessage(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/activity/chatbot`, {
        conversation: data?.conversation,
        user_id: data?.user_id,
      });

      return response.data;
    } catch (error: any) {
      console.error('Error sending message:', error.message);
      throw error;
    }
  }
}

const chatbotService = new ChatBot(`${process.env.REACT_APP_BASE_URL}`);
export default chatbotService;
