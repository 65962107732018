import React, { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

const NavLink = ({ children, to, onClick, ...props }: any) => (
  <Link
    style={props}
    as={ReactRouterLink}
    to={to}
    px={2}
    py={3}
    onClick={onClick}
    rounded={"md"}
    borderWidth="0px"
    _hover={{
      textDecoration: "none",
      color: "primaryLight",
      fontWeight: "semibold",
    }}
  >
    {children}
  </Link>
);

export default NavLink;
