import React from "react";
import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
// @ts-ignore
import errorScreen from "./../../Assets/images/error-screen.svg";

interface IFallbackProps {
  resetErrorBoundary: any;
}

const FallBack: React.FC<IFallbackProps> = ({ resetErrorBoundary }) => (
  <Box
    bg="white"
    height={"100vh"}
    width={"100vw"}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <VStack spacing={6} justifyContent={"flex-start"}>
      <Image src={errorScreen} />
      <Text fontSize="4xl" color={"primary"} fontWeight={700}>
        OOPS!
      </Text>
      <Text fontSize="md" color={"gray5"}>
        Something went wrong...
      </Text>
      <Button
        fontSize={"2xl"}
        backgroundColor={"primaryLight"}
        color={"foreground"}
        _hover={{
          backgroundColor: "primary",
        }}
        onClick={resetErrorBoundary}
      >
        Reload Application
      </Button>
    </VStack>
  </Box>
);

export default FallBack;
