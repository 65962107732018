import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const textAreaBaseStyle = defineStyle({
  bg: "gray1",
  borderRadius: "xl",
  padding: 4,
  w: "100%",
});

export const textAreaTheme = defineStyleConfig({
  baseStyle: textAreaBaseStyle,
});
