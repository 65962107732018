import { Box, HStack, Hide, IconButton, Text, VStack, chakra } from '@chakra-ui/react'; // @ts-ignore
import { ReactComponent as _ContractIcon } from './../../Assets/icons/collapse-icon.svg'; // @ts-ignore
import { ReactComponent as _CrossIcon } from './../../Assets/icons/cross-icon-chatbot.svg'; // @ts-ignore
import { ReactComponent as _ExpandIcon } from './../../Assets/icons/expand-icon.svg';

import React, { useState } from 'react';
import { CHATBOX_DIMENSIONS } from '../../Constants/chatbots';
import Chat from './Chat';
const ExpandIcon = chakra(_ExpandIcon);
const CrossIcon = chakra(_CrossIcon);
const ContractIcon = chakra(_ContractIcon);

interface ChatBoxProps {
  handleClickOnCross: any;
}

const Chatbox: React.FC<ChatBoxProps> = ({ handleClickOnCross }) => {
  const [dimensions, setDimensions] = useState({
    height: CHATBOX_DIMENSIONS.COLLAPSED.HEIGHT,
    width: CHATBOX_DIMENSIONS.COLLAPSED.WIDTH,
  });

  const handleClickOnExpand = () => {
    if (dimensions.width === CHATBOX_DIMENSIONS.COLLAPSED.WIDTH) {
      setDimensions({
        height: CHATBOX_DIMENSIONS.EXPANDED.HEIGHT,
        width: CHATBOX_DIMENSIONS.EXPANDED.WIDTH,
      });
    } else {
      setDimensions({
        height: CHATBOX_DIMENSIONS.COLLAPSED.HEIGHT,
        width: CHATBOX_DIMENSIONS.COLLAPSED.WIDTH,
      });
    }
  };

  return (
    <Box
      position='fixed'
      bottom='110px'
      right={['20px', '40px']}
      width={dimensions.width}
      height={dimensions.height}
      bg='white'
      borderRadius='xl'
      boxShadow='0px 0px 10px 0px rgba(52, 64, 84, 0.15)'
      zIndex='1000'
    >
      <VStack width={'100%'} spacing={2} paddingY={2} paddingX={2} height={'100%'}>
        <HStack justifyContent={'space-between'} width={'100%'} paddingX={2}>
          <Text fontWeight={600} fontSize={'sm'} color={'#344054'}>
            Kollegio Chat
          </Text>
          <HStack>
            <Hide below='md'>
              <IconButton
                background={'transparent'}
                _hover={{ background: 'gray9', borderRadius: 'none' }}
                onClick={handleClickOnExpand}
                aria-label='expand'
                size={'xs'}
                icon={
                  dimensions.width === CHATBOX_DIMENSIONS.COLLAPSED.WIDTH ? (
                    <ExpandIcon color={'#667085'} />
                  ) : (
                    <ContractIcon color={'#667085'} />
                  )
                }
              />
            </Hide>
            <IconButton
              background={'transparent'}
              _hover={{ background: 'gray9', borderRadius: 'none' }}
              onClick={handleClickOnCross}
              aria-label='cross'
              size={'xs'}
              icon={<CrossIcon color={'#667085'} />}
            />
          </HStack>
        </HStack>
        <Chat />
      </VStack>
    </Box>
  );
};

export default Chatbox;
