import { useBreakpointValue } from '@chakra-ui/react';
import { editorHeaderRefAtom } from 'Atoms/EssayWriter/essayWriter';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

const useDynamicEditorHeight = (isReviewMode = false) => {
  const [editorHeight, setEditorHeight] = useState(0);
  const headerRef = useAtomValue(editorHeaderRefAtom);
  const toolbarHeight = 250;
  const reviewModeHeader = useBreakpointValue({ base: 20, sm: 20, md: 20, lg: 5 });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEditorHeight = () => {
    const headerHeight = headerRef?.current ? headerRef?.current?.offsetHeight : 0;
    const newHeight = window.innerHeight - (isReviewMode ? reviewModeHeader : headerHeight) - toolbarHeight;
    setEditorHeight(newHeight);
  };

  useEffect(() => {
    updateEditorHeight();

    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, [toolbarHeight, headerRef, updateEditorHeight]);

  return { editorHeight, headerRef, updateEditorHeight };
};

export default useDynamicEditorHeight;
