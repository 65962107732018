import {
  AbsoluteCenter,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface CustomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  placement: 'top' | 'right' | 'bottom' | 'left';
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  showDrawerCloseButton?: boolean;
  showDrawerOverlay?: boolean;
  closeOnOverlayClick?: boolean;
  size?: string;
  isLoading?: boolean;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  isOpen,
  onClose,
  placement,
  header,
  body,
  footer,
  showDrawerCloseButton = false,
  showDrawerOverlay = false,
  closeOnOverlayClick = false,
  isLoading = false,
  size = 'xs',
}) => (
  <Drawer
    isOpen={isOpen}
    placement={placement}
    onClose={onClose}
    closeOnOverlayClick={closeOnOverlayClick}
    useInert={false}
    size={size}
  >
    {showDrawerOverlay && <DrawerOverlay />}
    <DrawerContent boxShadow='box-shadow: -4px 0px 16px -16px rgba(105, 108, 112, 0.10)'>
      {showDrawerCloseButton && (
        <DrawerCloseButton color={'gray5'} borderRadius={'50%'} bg={'gray1'} _hover={{ bg: 'blackAlpha.100' }} />
      )}
      <DrawerHeader>{header}</DrawerHeader>

      <DrawerBody>
        {isLoading ? (
          <Box>
            <AbsoluteCenter>
              <Spinner />
            </AbsoluteCenter>
          </Box>
        ) : (
          body
        )}
      </DrawerBody>

      <DrawerFooter>{footer}</DrawerFooter>
    </DrawerContent>
  </Drawer>
);

export default CustomDrawer;
