import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Hide,
  Show,
  Skeleton,
  Text,
  Tooltip,
  VStack,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect } from 'react';
import { notesAtom } from '../../Atoms/notesAtom';
import CustomDrawer from '../../lib/UI-Components/CustomDrawer';
// @ts-ignore
import { ReactComponent as _DockSideRight } from './../../Assets/icons/dock-side-right.svg';
// @ts-ignore
import { ReactComponent as _DockSideLeft } from './../../Assets/icons/dock-side-left.svg';
import NotesList from './NotesList';
// @ts-ignore
import NotesEmptyView from './../../Assets/images/empty-notes-view-image.svg';
// @ts-ignore
import { AnalyticsScreenName, Routes } from '../../Constants/constants';
import useDynamicEditorHeight from '../../Hooks/EssayWriter/useDynamicEditorHeight';
import { useAddNote, useGetNotes, useUpdateNote } from '../../Hooks/Notes/useNotes';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import Loading from '../../lib/UI-Components/Loading';
import PlaceholderImage from '../../lib/UI-Components/PlaceholderImage';
import { INotes } from '../../types/notes';
import { ReactComponent as _CrossIcon } from './../../Assets/icons/cross.svg';
import NotesComponent from './NotesComponent';

const DockSideRightIcon = chakra(_DockSideRight);
const DockSideLeftIcon = chakra(_DockSideLeft);
const CrossIcon = chakra(_CrossIcon);

const Notes = () => {
  const { trackEvent } = useMixpanel();
  const [notes, setNotes] = useAtom(notesAtom);
  const { updateNote } = useUpdateNote();
  const { data: notesData, isLoading } = useGetNotes();
  const { addNote, isPending } = useAddNote();
  const { updateEditorHeight } = useDynamicEditorHeight();

  useEffect(() => {
    if (notes?.isOpen && Routes.EssayWriter) {
      updateEditorHeight();
    }
  }, [notes?.isOpen]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    if (!isLoading && notesData) {
      const sortedNotes: INotes[] = [...notesData].sort((a, b) => a.priority - b.priority);

      setNotes((prevNotes: any) => ({
        ...prevNotes,
        notes: sortedNotes,
      }));
    }
  }, [notesData, isLoading, setNotes]);

  const header = (
    <HStack width={'100%'} justifyContent={'space-between'}>
      <Text color={'primary'}>
        {notes?.isCreateNoteView ? 'Create New Note' : notes?.isEditNoteView ? 'Edit Note' : 'Notes'}
      </Text>
      {!isMobile && (
        <HStack>
          <Tooltip label='Toggle Drawer Position'>
            <Button
              onClick={async (e) => {
                e.stopPropagation();
                trackEvent(`Notes placement changed`, {
                  screenName: AnalyticsScreenName.ScreenNotes,
                });

                if (notes?.placement === 'right') {
                  setNotes({ ...notes, placement: 'left' });
                } else {
                  setNotes({ ...notes, placement: 'right' });
                }
              }}
              onFocus={(e) => e.preventDefault()}
              size='xs'
              background={'background'}
              borderRadius={'full'}
              _hover={{
                backgroundColor: 'green1',
              }}
            >
              {notes?.placement === 'right' ? (
                <DockSideLeftIcon
                  color={'gray4'}
                  _hover={{
                    color: 'primaryLight',
                  }}
                />
              ) : (
                <DockSideRightIcon
                  color={'gray4'}
                  _hover={{
                    color: 'primaryLight',
                  }}
                />
              )}
            </Button>
          </Tooltip>
          <Button
            onClick={() => {
              trackEvent(`Notes cross icon pressed`, {
                screenName: AnalyticsScreenName.ScreenNotes,
              });
              setNotes({
                ...notes,
                isOpen: false,
                isCreateNoteView: false,
                isEditNoteView: false,
              });
            }}
            size='xs'
            background={'background'}
            borderRadius={'full'}
            _hover={{
              backgroundColor: 'green1',
            }}
          >
            <CrossIcon
              color={'gray5'}
              _hover={{
                color: 'primaryLight',
              }}
            />
          </Button>
        </HStack>
      )}
    </HStack>
  );

  const body = useCallback(
    () => (
      <>
        {isLoading && (
          <VStack justifyContent='center' alignItems='center' width='100%' height='50vh'>
            <Loading />
          </VStack>
        )}
        {(notesData?.length > 0 || notes?.isCreateNoteView || notes?.isEditNoteView) && <NotesList />}
        {notesData?.length === 0 && !notes?.isCreateNoteView && !notes?.isEditNoteView && (
          <VStack width={'100%'} height={isMobile ? '50vh' : '100%'} justifyContent={'center'} alignItems={'center'}>
            <PlaceholderImage
              imageSrc={NotesEmptyView}
              imageWidth={isMobile ? 'full' : '100%'}
              alt='Plan Illustration'
            />
            <Text color={'gray5'} textAlign={'center'}>
              You have no notes. Add new notes by clicking on Create New Note.
            </Text>
          </VStack>
        )}
      </>
    ),
    [isLoading, notesData, notes?.isCreateNoteView, notes?.isEditNoteView, isMobile]
  );

  const skeleton = (
    <>
      {Array.from({ length: 3 })?.map((_, index) => (
        <Skeleton
          key={`notes_drawer_${index}`}
          w='full'
          height='6vh'
          rounded='md'
          boxShadow='sm'
          mt={2}
          startColor='gray2'
          endColor='gray1'
        />
      ))}
    </>
  );

  const footer = (
    <Box
      width='100%'
      padding={[4, 0, 0]}
      position={['fixed', 'unset', 'unset']}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={'white'}
      zIndex={999}
      display={['block', 'flex']}
      justifyContent={['unset', 'space-between']}
    >
      {notes?.isCreateNoteView || notes?.isEditNoteView ? (
        <>
          <Show below='md'>
            <VStack width='100%' spacing={4}>
              <Button
                width={'100%'}
                variant={'secondary'}
                fontSize={'sm'}
                onClick={() => {
                  trackEvent(`Back to Notes Pressed`, {
                    screenName: AnalyticsScreenName.ScreenNotes,
                  });
                  setNotes({
                    ...notes,
                    selectedNoteId: null,
                    textFieldContent: '',
                    isCreateNoteView: false,
                    isEditNoteView: false,
                  });
                }}
              >
                Back to Notes
              </Button>
              <Button
                _hover={{ bg: 'primaryLight' }}
                variant={'activityPrimary'}
                fontSize={'sm'}
                width={'100%'}
                isDisabled={isPending}
                onClick={() => {
                  trackEvent(`Save Note pressed`, {
                    screenName: AnalyticsScreenName.ScreenNotes,
                  });

                  if (notes?.isCreateNoteView) {
                    addNote(notes?.textFieldContent);
                  } else if (notes?.isEditNoteView) {
                    updateNote({
                      noteId: notes.selectedNoteId,
                      updatedContent: notes?.textFieldContent || '',
                    });
                  }
                }}
              >
                Save Note
              </Button>
            </VStack>
          </Show>
          <Hide below='md'>
            <HStack width='100%' spacing={4} justifyContent={'space-between'}>
              <Button
                width={'48%'}
                variant={'secondary'}
                fontSize={'sm'}
                onClick={() => {
                  trackEvent(`Back to Notes Pressed`, {
                    screenName: AnalyticsScreenName.ScreenNotes,
                  });
                  setNotes({
                    ...notes,
                    selectedNoteId: null,
                    textFieldContent: '',
                    isCreateNoteView: false,
                    isEditNoteView: false,
                  });
                }}
              >
                Back to Notes
              </Button>
              <Button
                _hover={{ bg: 'primaryLight' }}
                variant={'activityPrimary'}
                fontSize={'sm'}
                width={'48%'}
                isDisabled={isPending}
                onClick={() => {
                  trackEvent(`Save Note pressed`, {
                    screenName: AnalyticsScreenName.ScreenNotes,
                  });

                  if (notes?.isCreateNoteView) {
                    addNote(notes?.textFieldContent);
                  } else if (notes?.isEditNoteView) {
                    updateNote({
                      noteId: notes.selectedNoteId,
                      updatedContent: notes?.textFieldContent || '',
                    });
                  }
                }}
              >
                Save Note
              </Button>
            </HStack>
          </Hide>
        </>
      ) : (
        <Button
          color={'primaryLight'}
          width={'100%'}
          borderRadius={'xl'}
          onClick={() => {
            trackEvent(`Create New Note button pressed`, {
              screenName: AnalyticsScreenName.ScreenNotes,
            });
            setNotes({ ...notes, isCreateNoteView: true });
          }}
          _hover={{
            backgroundColor: 'rgba(3, 90, 65, 0.08)',
          }}
          backgroundColor={'rgba(62, 138, 117, 0.16)'}
        >
          <AddIcon mr={2} /> {' Create New Note '}
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <Show below='md'>
        <Hide above='767px'>
          <NotesComponent
            onClose={() => {
              setNotes({
                ...notes,
                isOpen: false,
                isCreateNoteView: false,
                isEditNoteView: false,
              });
            }}
          >
            {body()}
            {footer}
          </NotesComponent>
        </Hide>
      </Show>
      <Show above='md'>
        <CustomDrawer
          isOpen={notes?.isOpen}
          onClose={() =>
            setNotes({
              ...notes,
              isOpen: false,
              isCreateNoteView: false,
              isEditNoteView: false,
            })
          }
          placement={notes?.placement}
          header={header}
          body={isLoading ? skeleton : body()}
          footer={!isLoading && footer}
        />
      </Show>
    </>
  );
};

export default Notes;
