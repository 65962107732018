import * as Sentry from '@sentry/react';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { userAtom } from '../../Atoms/App/user';

const SentryUserManager = () => {
  const user = useAtomValue(userAtom);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user?.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return null;
};

export default SentryUserManager;
