import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
// INFO: Removing Post hog from bundle until some A/B testing need arises

// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// };

import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from './Components/App/Fallback';
import SentryUserManager from './Components/App/SentryManager';
import UserManager from './Components/UserManager.tsx';
import usePixel from './Hooks/App/usePixel.tsx';
import useSessionTracker from './Hooks/App/useSessionTracker.tsx';
import useAuth from './Hooks/Auth/useAuth.tsx';
import Views from './Routes/Views';
import { setLogOutCallback } from './Utils/auth.ts';
import { detectDarkMode } from './Utils/common';
import useMixpanel from './lib/Analytics/Mixpanel/useMixpanel';
import { AlertWrapper } from './lib/UI-Components/Alert';
// INFO: Removing Post hog from bundle until some A/B testing need arises

// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// };

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function logErrorToService(error, info) {
  console.error('Log to service - Caught an error :', error, info);
  Sentry.captureException(error);
}

function App() {
  const queryClient = new QueryClient();
  const { logOut } = useAuth();
  const { incrementSessionCount } = useSessionTracker();

  const { initializeMixpanel } = useMixpanel();
  usePixel();
  useEffect(() => {
    const cleanup = detectDarkMode();
    initializeMixpanel();

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  useEffect(() => {
    setLogOutCallback(logOut, incrementSessionCount);

    return () => {
      setLogOutCallback(null, null);
    };
  }, [logOut, incrementSessionCount]);

  return (
    <ErrorBoundary
      FallbackComponent={FallBack}
      onReset={() => {
        window.location.reload();
      }}
      onError={logErrorToService}
    >
      {/* <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}> */}
      <DevTools />
      <AlertWrapper>
        <UserManager>
          <QueryClientProvider client={queryClient}>
            <Views />
            <SentryUserManager />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </UserManager>
      </AlertWrapper>
      {/* </PostHogProvider> */}
    </ErrorBoundary>
  );
}

export default App;
