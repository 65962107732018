import { Spinner } from '@chakra-ui/react';
import { userAtom } from 'Atoms/App/user';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../Hooks/Auth/useAuth';
import authService from '../Services/auth';
import { isPublicRoute } from '../Utils/auth';
import { LoginResponse } from '../types/auth';

const UserManager: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const setUserAtom = useSetAtom(userAtom);
  const location = useLocation();
  const { logOut } = useAuth();
  const BASE_URL = process.env.REACT_APP_BASE_URL || '';

  useEffect(() => {
    const fetchUser = async () => {
      if (!isPublicRoute(location.pathname)) {
        try {
          const response = await authService.loginWithCookies();

          if (isLoginResponse(response)) {
            if (response?.status === 401) {
              throw new Error('Unauthorized');
            }

            const { data } = response;

            if (data && data.loggedIn) {
              setUserAtom(data);
              localStorage.setItem('user', JSON.stringify(data));
            } else {
              setUserAtom({ loggedIn: false });
              localStorage.removeItem('user');
            }
          } else {
            throw new Error('Unexpected response structure');
          }
        } catch (error) {
          console.error('Login failed:', error);

          if (axios.isAxiosError(error) && error?.response?.status === 401) {
            logOut();
          }

          console.error('Error fetching user data:', error);
          setUserAtom({ loggedIn: false });
          localStorage.removeItem('user');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [location.pathname, BASE_URL]);

  function isLoginResponse(response: any): response is LoginResponse {
    return (
      response &&
      typeof response?.status === 'number' &&
      response?.data &&
      typeof response?.data?.loggedIn === 'boolean'
    );
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUserAtom(JSON.parse(storedUser));
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Spinner />; // Provide a loading indicator or similar
  }

  return <>{children}</>;
};

export default UserManager;
