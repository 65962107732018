import { Alert, AlertIcon, Box, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import useLottieAnimation from '../../../Hooks/App/useFetchLottie';
import useFilteredChoices from '../../../Hooks/Demo/useFilteredChoices';
import PlaceholderImage from '../PlaceholderImage';
import DemoChoice from './DemoChoice';
const Lottie = React.lazy(() => import('lottie-react'));

interface DemoTooltipContentProps {
  isAnimation?: boolean;
  imageSrc?: string;
  animationUrl?: string;
  title: string;
  content?: string;
  showChoices?: boolean;
}

const DemoTooltipContent = ({
  isAnimation = false,
  imageSrc,
  animationUrl,
  title,
  content,
  showChoices = false,
}: DemoTooltipContentProps) => {
  const { animationData, loading, error } = useLottieAnimation(animationUrl || '');
  const { choices, manualTrigger } = useFilteredChoices();

  const renderAnimation = () =>
    loading ? (
      <Box w={'auto'} h={'20vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Spinner size='lg' />
      </Box>
    ) : error ? (
      <Alert status='error'>
        <AlertIcon />
        Error loading animation.
      </Alert>
    ) : (
      <Suspense fallback={<Spinner size='lg' />}>
        <Lottie animationData={animationData} height={'auto'} width={'auto'} />
      </Suspense>
    );

  const renderImage = () => <PlaceholderImage imageSrc={imageSrc} />;

  return (
    <VStack w={'full'} h={'full'} gap={2} justifyContent={'center'} alignItems={'center'} mb={2}>
      <Box w={'full'} h={'full'} bg={'#f6f6f6'} p={2}>
        {isAnimation ? renderAnimation() : renderImage()}
      </Box>
      <VStack w={'full'} px={4}>
        {showChoices ? (
          <Text color={'gray6'} fontWeight={'semibold'} fontSize={['xs', 'sm', 'md']} align={'start'} w={'full'}>
            {choices?.length > 0 && !manualTrigger && showChoices ? title : 'You’re All Set! 🎉'}
          </Text>
        ) : (
          <Text color={'gray6'} fontWeight={'semibold'} fontSize={['xs', 'sm', 'md']} align={'start'} w={'full'}>
            {title}
          </Text>
        )}
        {showChoices && choices?.length > 0 && !manualTrigger ? (
          <DemoChoice choices={choices} />
        ) : (
          <Text color={'gray5'} fontSize={['xs', 'sm', 'sm']} fontWeight={'medium'} align={'start'} w={'full'}>
            {content}
          </Text>
        )}
      </VStack>
    </VStack>
  );
};

export default DemoTooltipContent;
