import React from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";

interface ProfileAvatarProps extends FlexProps {
  username: string;
  props?: any;
}

const ProfileAvatar = ({ username, ...props }: ProfileAvatarProps) => (
  <Flex
    p={2}
    rounded={"md"}
    cursor={"pointer"}
    bg="primaryLight"
    alignItems={"center"}
    justifyContent={"center"}
    color="foreground"
    borderRadius={"50%"}
    textDecoration={"none"}
    fontWeight={"semibold"}
    {...props}
  >
    <Text>{username?.charAt(0).toUpperCase()}</Text>
  </Flex>
);

export default ProfileAvatar;
