import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { triggerLogOut } from 'Utils/auth';

const { REACT_APP_BASE_URL } = process.env;
const TIMEOUT_DURATION = 60000;

export class HttpService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: REACT_APP_BASE_URL,
      timeout: TIMEOUT_DURATION,
      paramsSerializer: (params) => queryString.stringify(params),
      withCredentials: true,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => this.handleErrorResponse(error)
    );
  }

  private handleErrorResponse(error: any) {
    if (error.response) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          triggerLogOut();
        }, 2000);
      }

      if (error.response.data?.error) {
        console.error('API error: ', error.response.data?.error);
      }

      return Promise.reject(error.response.data);
    } else if (error.request) {
      // No response from server
      console.error('Network error: ', error.message);
      return Promise.reject({ message: 'Network error, please try again later' });
    }

    return Promise.reject(error);
  }

  async get<T>(url: string, queryParams?: Record<string, any>, options?: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.get<T>(url, { ...options, params: queryParams });
    return response.data;
  }

  async post<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.post<T>(url, data, options);
    return response.data;
  }

  async put<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.put<T>(url, data, options);
    return response.data;
  }

  async patch<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.patch<T>(url, data, options);
    return response.data;
  }

  async delete<T>(url: string, queryParams?: Record<string, any>, options?: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.delete<T>(url, { ...options, params: queryParams });
    return response.data;
  }
}

export const httpService = new HttpService();
