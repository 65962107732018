import { Flex, Text, chakra } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { bannerAtom } from '../../Atoms/bannerAtom';
import { AnalyticsScreenName } from '../../Constants/constants';
import useMixpanel from '../Analytics/Mixpanel/useMixpanel';
import { ReactComponent as _CrossIcon } from './../../Assets/icons/cross.svg';

const CrossIcon = chakra(_CrossIcon);

export const Banner: React.FC = () => {
  const [banner, setBanner] = useAtom(bannerAtom);
  const { trackEvent } = useMixpanel();
  return (
    <>
      {banner?.isVisible && (
        <Flex
          width={'100%'}
          height={['7vh', '7vh', '5vh']}
          background={'linear-gradient(147deg, #035A41 -5.79%, #3E8A75 104.73%);'}
          overflow={'hidden'}
          alignItems={'center'}
          paddingX={4}
          justifyContent={'space-between'}
        >
          <Text />
          <Text color={'foreground'} fontWeight={500} fontSize={['xs', 'sm']}>
            {banner.message}
          </Text>
          <CrossIcon
            color={'foreground'}
            width={'15px'}
            height={'15px'}
            strokeWidth={1}
            onClick={() => {
              trackEvent(`Banner Closed`, {
                screenName: AnalyticsScreenName.ScreenColleges,
              });
              setBanner({ ...banner, isVisible: false });
              sessionStorage.setItem(banner.name, JSON.stringify({ ...banner, isVisible: false }));
            }}
            _hover={{
              color: 'background',
            }}
          />
        </Flex>
      )}
    </>
  );
};
