import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getScreenNameFromRoute } from '../../Constants/constants';
import { useReportIssue } from '../../Hooks/Report/useReport';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import PlaceholderImage from '../../lib/UI-Components/PlaceholderImage';
import SelectFieldV2 from '../Form/SelectFieldV2';
import TextFieldArea from '../Form/TextFieldArea'; // @ts-ignore
import reportProblemImage from './../../Assets/images/report-problem.svg';

const Report = ({ onClose }: any) => {
  const [error] = useState(null);
  const { trackEvent } = useMixpanel();
  const location = useLocation();
  const { reportProblem } = useReportIssue();
  return (
    <Formik
      initialValues={{
        type: '',
        description: '',
      }}
      onSubmit={(values, actions) => {
        onClose();
        actions.resetForm();
        reportProblem(values);
      }}
    >
      <VStack as={Form} w={'100% '} color={'gray5'} spacing={4} pb={4} px={2}>
        <PlaceholderImage imageSrc={reportProblemImage} />
        <VStack w={'100% '} spacing={1}>
          <Text color='gray5' textAlign='left' w='100%' fontSize={'md'}>
            Type of problem
            <Text as='span' ml={1} color='red'>
              *
            </Text>
          </Text>
          <Text as='p' color='red.500'>
            {error}
          </Text>
          <SelectFieldV2
            name='type'
            emptyLabel='Select a type...'
            options={['General Feedback', 'Wrong Data', 'Broken Functionality']}
            isRequired
            borderRadius={'xl'}
          />
        </VStack>
        <VStack w={'100%'} spacing={1}>
          <Text color='gray.500' textAlign='left' w='100%' fontSize={'md'}>
            Description
            <Text as='span' ml={1} color='red'>
              *
            </Text>
          </Text>
          <Box w={'100%'}>
            <TextFieldArea
              name='description'
              placeholder='Enter description'
              autoComplete='off'
              flexDir='column'
              isRequired
            />
          </Box>
        </VStack>
        <Button
          w={'100%'}
          variant={'primaryWithoutShadow'}
          textTransform={'uppercase'}
          fontSize={'sm'}
          fontWeight={'bold'}
          type='submit'
          onClick={() =>
            trackEvent('Submit Report Form', {
              screenName: getScreenNameFromRoute(location.pathname),
              label: 'Click on Submit Button in Report a Problem Modal',
            })
          }
        >
          Submit
        </Button>
      </VStack>
    </Formik>
  );
};

export default Report;
