import { Routes } from 'Constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';

const routePatterns = [
  { pattern: /^\/essays\/edit\/\d+$/, route: Routes.EssayWriter },
  { pattern: /^\/essays\/view\/[a-zA-Z0-9]+:[a-zA-Z0-9]+$/, route: Routes.EssayReviewer },
  { pattern: /^\/activities\/edit\/\d+$/, route: Routes.EditActivity },
  { pattern: /^\/college\/\d+$/, route: Routes.CollegeDetails },
  { pattern: /^\/recommendations\/\d+$/, route: Routes.RecommendationsExpanded },
  { pattern: /^\/colleges\/chance-me\/[a-zA-Z0-9-]+$/, route: Routes.SingleUniversity },
];

export const mapPathToRoute = (pathname: string) => {
  const match = routePatterns?.find(({ pattern }) => pattern?.test(pathname));
  return match ? match?.route : pathname;
};

export const useRedirectWithQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const redirectWithParams = (targetRoute: string) => {
    const currentParams = location.search;
    const newRoute = `${targetRoute}${currentParams}`;
    navigate(newRoute);
  };

  return redirectWithParams;
};

export function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);

  const utmParams: Record<string, string> = {};
  const referralParams: Record<string, string> = {};
  const otherParams: Record<string, string> = {};
  let variant = '';

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    } else if (key.startsWith('referral_')) {
      referralParams[key] = value;
    } else if (key === 'variant') {
      variant = value;
    } else {
      otherParams[key] = value;
    }
  });

  return { utmParams, referralParams, otherParams, variant };
}
