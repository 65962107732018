import { useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { isFirstTimeUser } from '../../../Atoms/App/user';
import { profileProgressAtom } from '../../../Atoms/Profile/profile';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import ProfileModal from './ProfileModal';

const ProfileCompletion: React.FC = () => {
  const { trackEvent } = useMixpanel();

  const [isModalOpen, setModalOpen] = useState(false);
  const profileProgressPercentage = useAtomValue(profileProgressAtom);
  const isFirstTimeLogin = useAtomValue(isFirstTimeUser);

  const sessionCount = parseInt(localStorage.getItem('sessionCount') || '0', 10);
  const profileModalCount = parseInt(localStorage.getItem('profileCompletionModal') || '0', 10);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = useCallback(() => {
    if (profileProgressPercentage <= 10 && profileModalCount === 0 && !isFirstTimeLogin) {
      setModalOpen(true);
      trackEvent('Profile Modal Displayed');
      localStorage.setItem('profileCompletionModal', (1).toString());
    }
  }, [profileModalCount]);

  useEffect(() => {
    const timer = setTimeout(() => {
      openModal();
    }, 10 * 1000);

    return () => clearTimeout(timer);
  }, [profileModalCount, openModal, sessionCount]);

  return <ProfileModal isOpen={isModalOpen} onClose={closeModal} />;
};

export default ProfileCompletion;
