import * as Yup from 'yup';

export const PROFILE_BACKGROUND_GROUPS = [
  {
    id: 1,
    heading: 'Group 1: Academic and Extracurricular Background',
    subheading: 'Share your academic interests and extracurricular achievements.',
    progress: 0,
    positions: [2, 3, 4],
  },
  {
    id: 2,
    heading: 'Group 2: Personal Challenges, Values, and Interests',
    subheading: 'Tell us about your challenges, values, and hobbies.',
    progress: 0,
    positions: [5, 6],
  },
  {
    id: 3,
    heading: 'Group 3: Personality, Character, and Additional Insights',
    subheading: 'Describe your personality and any extra insights.',
    progress: 0,
    positions: [7, 8, 9],
  },
];

export const TOTAL_BACKGROUND_QUESTIONS = 9;

export const MAX_WORDS_BACKGROUND_QUESTIONS = 50;

export const profileValidationSchema = Yup.object().shape({
  country: Yup.string().nullable(),
  state: Yup.string().nullable(),
  income: Yup.string().nullable(),
  ethnicity: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  gpa: Yup.number().min(0, 'GPA too low').max(5, 'GPA too high').nullable().typeError('GPA must be a number'),
  satmath: Yup.number()
    .min(200, 'Invalid SAT Math score')
    .max(800, 'Invalid SAT Math score')
    .nullable()
    .typeError('SAT score must be a number'),
  satenglish: Yup.number()
    .min(200, 'Invalid SAT English score')
    .max(800, 'Invalid SAT English score')
    .nullable()
    .typeError('SAT score must be a number'),
  act: Yup.number()
    .min(1, 'Invalid ACT score')
    .max(36, 'Invalid ACT score')
    .nullable()
    .typeError('ACT score must be a number'),
  highschool: Yup.string().max(100, 'Maximum 100 characters').nullable(),
  classrank: Yup.number().min(1, 'Invalid class rank').nullable(),
  gradyear: Yup.number().nullable(),
  standardized_tests: Yup.boolean().nullable(),
  intake_year: Yup.string().nullable(),
  study: Yup.string().nullable(),
  interest: Yup.string().nullable(),
});

export const updatePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string().min(8, 'Password must be at least 8 characters long').required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm password is required'),
});

const CURRENT_YEAR = new Date().getFullYear();
export const YEARS = Array.from({ length: 10 }, (_, i) => CURRENT_YEAR + i);
