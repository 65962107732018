import { AxiosError } from 'axios';
import { HttpService } from './http';

class Report extends HttpService {
  apiBaseUrl: string;
  apiBaseUrlV2: string;
  constructor(apiBaseUrl: string) {
    super();
    this.apiBaseUrl = apiBaseUrl;
    this.apiBaseUrlV2 = `${apiBaseUrl}/v2/report`;
  }

  async reportProblem(data: any) {
    try {
      const response: any = await this.post(`${this.apiBaseUrlV2}/`, data);

      return response.data;
    } catch (error) {
      console.error('Error reporting a problem', error);
      return (error as AxiosError)?.response?.data;
    }
  }
}
const reportService = new Report(`${process.env.REACT_APP_BASE_URL}`);
export default reportService;
