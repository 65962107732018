import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { useField } from "formik";
import {
  Flex,
  FormControlProps,
  FormLabelProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import React from "react";
import FormattedInput from "./FormattedInput";

interface TextFieldAreaProps extends FormLabelProps {
  label?: string;
  name: string;
  alignLabel?: string;
  gpaFormat?: boolean;
  isShowLabelInField?: boolean;
  showError?: boolean;
  labelSize?: any;
}

const TextFieldArea = ({
  label,
  flexDir,
  alignLabel,
  name,
  labelSize = ["sm", "sm", "md"],
  gpaFormat = false,
  isShowLabelInField = false,
  showError = true,
  ...props
}: TextFieldAreaProps & TextareaProps & FormControlProps) => {
  const [field, meta] = useField(name);
  return (
    <FormControl isInvalid={meta.touched && meta.error ? true : undefined}>
      <Flex flexDirection={flexDir} alignItems={alignLabel}>
        {label && !isShowLabelInField ? (
          <FormLabel {...props}>{label}</FormLabel>
        ) : null}
        <Flex
          flexDirection={"column"}
          style={{ width: "100%" }}
          bg={"gray1"}
          borderRadius={"xl"}
          border={"1px solid #E2E8F0"}
        >
          {label && isShowLabelInField ? (
            <FormLabel
              transition="all 0.2s"
              fontSize={"10px"}
              paddingTop={0.5}
              w={"max-content"}
              paddingLeft={3}
              color={meta.error ? "red" : "gray3"}
              zIndex={10}
              {...props}
            >
              {label}
            </FormLabel>
          ) : null}
          {gpaFormat ? (
            <FormattedInput
              bg={"gray1"}
              borderRadius={"xl"}
              w={"100%"}
              fontSize={labelSize}
              {...props}
            />
          ) : (
            <Textarea
              bg={"gray1"}
              border={"none"}
              w={"100%"}
              resize={"none"}
              pt={label && isShowLabelInField ? 0 : "10px"}
              fontSize={labelSize}
              borderRadius={"xl"}
              focusBorderColor="transparent"
              {...field}
              {...props}
            />
          )}
          {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default TextFieldArea;
