import { Button, Text, WrapItem } from '@chakra-ui/react';
import React from 'react';

interface ChoiceButtonProps {
  label: string;
  index: number;
  selected: string;
  setSelected: (link: string) => void;
  link: string;
}

const ChoiceButton = ({ link, label, selected, setSelected }: ChoiceButtonProps) => (
  <WrapItem>
    <Button
      onClick={() => setSelected(link)}
      isActive={selected === link}
      color={'gray17'}
      paddingY={'12px'}
      paddingX={'16px'}
      borderRadius={'12px'}
      bg={'white'}
      border={'1px solid'}
      borderColor={'gray14'}
      _hover={{
        bg: 'white',
        color: 'green3',
        border: '2px solid',
        borderColor: 'green3',
      }}
      _active={{
        bg: 'green6',
        color: 'green3',
        fontWeight: '700',
        border: '2px solid',
        borderColor: 'green3',
      }}
      fontSize={'12px'}
      fontWeight={'500'}
    >
      <Text>{label}</Text>
    </Button>
  </WrapItem>
);

export default ChoiceButton;
