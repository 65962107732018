import { atom } from 'jotai';

interface IDashboard {
    isMobilePopUpVisible: boolean;
}

export const dashboardAtom = atom<IDashboard>({
    isMobilePopUpVisible: false,
});

