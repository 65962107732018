import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { alertAtom } from 'Atoms/alertAtom';
import { notesAtom } from 'Atoms/notesAtom';
import { ALERT_TYPES } from 'Constants/constants';
import notesService from 'Services/notes';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const useGetNotes = () => {
  const [notes, setNotes] = useAtom(notesAtom);

  const { data, isSuccess, isError, isLoading } = useQuery({
    queryKey: ['notes'],
    queryFn: () => notesService.getNotes(),
    retry: false,
    refetchOnWindowFocus: 'always',
    refetchOnMount: true,
    enabled: notes?.isOpen,
  });

  useEffect(() => {
    if (data) {
      setNotes((prev: any) => ({ ...prev, notes: data }));
    }
  }, [data]);

  return { data, isSuccess, isError, isLoading };
};

const useAddNote = () => {
  const [notes, setNotes] = useAtom(notesAtom);
  const setAlert = useSetAtom(alertAtom);
  const queryClient = useQueryClient();

  const { mutate: addNote, isPending } = useMutation({
    mutationFn: async (newNoteContent: string) => {
      const maxPriority = notes.notes?.reduce((max, note) => (note.priority > max ? note.priority : max), 0);
      return notesService.addNote({
        content: newNoteContent,
        priority: maxPriority + 1 || 1,
      });
    },
    onSuccess: (newNote) => {
      queryClient.invalidateQueries({ queryKey: ['notes'], exact: true });
      setNotes((prevNotes: any) => ({
        ...prevNotes,
        isCreateNoteView: false,
        notes: [...prevNotes.notes, newNote],
        textFieldContent: '',
      }));
      setAlert({ isVisible: true, message: 'Note added', type: ALERT_TYPES.SUCCESS });
    },
    onError: ({ error }: any) => {
      setAlert({ isVisible: true, message: error ? error : 'Note not added', type: ALERT_TYPES.ERROR });
    },
  });

  return { addNote, isPending };
};

const useUpdateNote = () => {
  const setNotes = useSetAtom(notesAtom);
  const setAlert = useSetAtom(alertAtom);

  const { mutate: updateNote } = useMutation({
    mutationFn: async ({ noteId, updatedContent }: { noteId: number | null; updatedContent: string }) =>
      notesService.updateNote(noteId, { content: updatedContent }),
    onSuccess: (updatedNote: any) => {
      setNotes((prevNotes: any) => ({
        ...prevNotes,
        isEditNoteView: false,
        isCreateNoteView: false,
        textFieldContent: '',
        notes: prevNotes?.notes.map((note: any) => (note?.id === updatedNote?.id ? updatedNote : note)),
      }));
      setAlert({ isVisible: true, message: 'Note updated', type: ALERT_TYPES.SUCCESS });
    },
    onError: ({ error }: any) => {
      setAlert({ isVisible: true, message: error ? error : 'Note not updated', type: ALERT_TYPES.ERROR });
      console.error('Error updating note:', error);
    },
  });

  return { updateNote };
};

const useUpdateNotesOrder = () => {
  const { mutate: updateNotesOrder } = useMutation({
    mutationFn: async (order: number[]) => notesService.updateNotesOrder({ order }),
    onError: (error) => {
      console.error('Error updating notes order:', error);
    },
  });

  return { updateNotesOrder };
};

const useDeleteNote = () => {
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  const { mutate: deleteNote, isPending } = useMutation({
    mutationFn: async (noteId: number) => notesService.deleteNote(Number(noteId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notes'] });
      await queryClient.refetchQueries({ queryKey: ['notes'], exact: true });

      setAlert({ isVisible: true, message: 'Note deleted successfully', type: ALERT_TYPES.SUCCESS });
    },
    onError: ({ error }: any) => {
      setAlert({ isVisible: true, message: error ? error : 'Note not updated', type: ALERT_TYPES.ERROR });
      console.error('Error deleting note:', error);
    },
  });

  return { deleteNote, isPending };
};

export { useAddNote, useDeleteNote, useGetNotes, useUpdateNote, useUpdateNotesOrder };
