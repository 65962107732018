import axios, { AxiosError } from 'axios';

interface LoginInputParamters {
  email: string;
  password: string;
}

class AuthService {
  apiBaseUrl: any;
  constructor(apiBaseUrl: any) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async login(data: LoginInputParamters) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/auth/login`, data);
      return response.data;
    } catch (error) {
      console.error('Error logging in:', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async loginWithCookies() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/auth/login`, {
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error('Error logging in:', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async signUp(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/auth/signup`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error signing up', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async logOut() {
    try {
      const response = await axios.post(
        `${this.apiBaseUrl}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error logging out', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async forgotPassword(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/auth/forgot-password`, data);

      return response.data;
    } catch (error) {
      console.error('Error sending reset email:', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async resetPassword(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/auth/reset-password`, data);

      return response.data;
    } catch (error) {
      console.error('Error reset password:', error);
      return (error as AxiosError)?.response?.data;
    }
  }

  async changePassword(data: any) {
    try {
      const response = await axios.post(`${this.apiBaseUrl}/auth/changepassword`, data, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  }
}

const authService = new AuthService(`${process.env.REACT_APP_BASE_URL}`);
export default authService;
