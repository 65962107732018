import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { userIdAtom } from '../../Atoms/App/user';
import { manualTriggerAtom } from '../../Atoms/demoAtom';
import { DEMO_CHOICE_BUTTON_LABELS, getLocalStorageKeys } from '../../Constants/demo';

const useFilteredChoices = () => {
  const userId = useAtomValue(userIdAtom);
  const manualTrigger = useAtomValue(manualTriggerAtom);

  const visitedPages: any = localStorage.getItem('visited') as string;
  const storedPageVisitedValues = JSON.parse(visitedPages);
  const isSameUser = storedPageVisitedValues?.user?.id === userId;
  const localStorageKeys = getLocalStorageKeys();

  const [choices, setChoices] = useState(DEMO_CHOICE_BUTTON_LABELS);

  useEffect(() => {
    DEMO_CHOICE_BUTTON_LABELS.forEach(({ link }) => {
      if (
        storedPageVisitedValues?.pageVisits &&
        storedPageVisitedValues.pageVisits[localStorageKeys[link]] &&
        isSameUser
      ) {
        setChoices((prev) => prev.filter(({ link: l }) => l !== link));
      }
    });
  }, [storedPageVisitedValues, localStorageKeys, isSameUser]);

  return { choices, manualTrigger };
};

export default useFilteredChoices;
