import { VStack, Wrap } from '@chakra-ui/react';
import React from 'react';

import { useAtom } from 'jotai';
import { demoChoiceLinkAtom } from '../../../Atoms/demoAtom';
import ChoiceButton from './ChoiceButton';
interface DemoChoiceProps {
  title?: string;
  choices?: { label: string; link: string }[];
}

const DemoChoice: React.FC<DemoChoiceProps> = ({ choices }) => {
  const [selected, setSelected] = useAtom(demoChoiceLinkAtom);

  return (
    <VStack w='full' h='full' gap={4} justifyContent='center' alignItems='center' mb={2}>
      <VStack w='full' spacing={2}>
        <Wrap marginY={'20px'} spacing={4} justify='center'>
          {choices?.map(({ label, link }, index) => (
            <ChoiceButton
              link={link}
              selected={selected}
              key={label}
              label={label}
              setSelected={setSelected}
              index={index}
            />
          ))}
        </Wrap>
      </VStack>
    </VStack>
  );
};

export default DemoChoice;
