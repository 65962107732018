import { useEffect, useState } from 'react';
import { isMobile } from '../../Utils/common';

interface UseLottieAnimationResult {
  animationData: any | null;
  loading: boolean;
  hideOnMobile?: boolean;
  error: boolean;
}

const useLottieAnimation = (url: string, hideOnMobile = false): UseLottieAnimationResult => {
  const [animationData, setAnimationData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isMobile() && hideOnMobile) {
      setLoading(false);
      return;
    }

    const fetchAnimationData = async () => {
      if (!url) {
        return;
      }

      setLoading(true);
      setError(false);

      try {
        // Detect if the URL is for an image or Lottie JSON
        const isLottie = url.endsWith('.json');
        const isImage = /\.(png|jpg|jpeg|svg|gif)$/i.test(url);

        if (isImage) {
          setAnimationData(url); // Store the image URL
        } else if (isLottie) {
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }

          const jsonData = await response.json();
          setAnimationData(jsonData); // Store the Lottie JSON data
        } else {
          throw new Error(`Unsupported URL format: ${url}`);
        }
      } catch (e) {
        setError(true);
        console.error('Error fetching data:', e);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimationData();
  }, [url]);

  return { animationData, loading, error };
};

export default useLottieAnimation;
