import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const radioBaseStyle = definePartsStyle({
  control: {
    bg: 'foreground',
    _checked: {
      bg: 'primaryDark',
    },
    borderWidth: '1px',
    boxShadow: 'sm',
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle: radioBaseStyle });
