import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxBaseStyle = definePartsStyle({
  control: {
    bg: "foreground",
    _checked: {
      bg: "primary",
    },
    borderWidth: "1px",
    boxShadow: "sm",
    borderRadius: "sm",
    padding: 2,
    _focusVisible: { outline: "none" },
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle: checkboxBaseStyle,
});
