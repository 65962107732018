import { HttpService } from './http';

class LoggingService extends HttpService {
  apiBaseUrl: string;
  constructor(apiBaseUrl: string) {
    super();
    this.apiBaseUrl = `${apiBaseUrl}/log`;
  }

  async logAiPerformance(data: any) {
    try {
      const response: APIResponse<UpdateUserResponse> = await this.patch(`${this.apiBaseUrl}/aiPerformance`, data);

      return response.data;
    } catch (error) {
      console.error('Error logging AI performance:', error);
      throw error;
    }
  }
}

const loggingService = new LoggingService(`${process.env.REACT_APP_BASE_URL}`);

export default loggingService;
