import { OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { IEssay } from '../types/essays';

export const getHighlightColor = (tone) => {
  if (tone === 'negative') return '#FACBCB';
  if (tone === 'positive') return '#B4F1AA';
  return false;
};

export const deltaToText = (delta: { ops: any[] }) => {
  let text = '';
  const indexMap: number[] = []; // Array to keep track of character indices

  delta.ops.forEach((op: any) => {
    if (op.insert) {
      if (typeof op.insert === 'string') {
        for (let i = 0; i < op.insert?.length; i++) {
          indexMap.push(text?.length);
          text += op.insert[i];
        }
      } else if (op.insert.image || op.insert.video) {
        indexMap.push(text?.length);
        text += ' ';
      }
    }
  });

  return { text, indexMap };
};

export const getSentenceStartIndices = (delta: any) => {
  const { text, indexMap } = deltaToText(delta);

  const sentenceRegex = /([^.!?]+[.!?])/g;
  const sentences = text.match(sentenceRegex) || [];

  const sentenceIndices: number[] = [];
  let currentIndex = 0;

  sentences.forEach((sentence) => {
    const sentenceStart = text.indexOf(sentence, currentIndex);
    sentenceIndices.push(indexMap[sentenceStart]);
    currentIndex = sentenceStart + sentence?.length;
  });

  return sentenceIndices;
};

export function highlightSentence(quill, sentenceIndex, color) {
  const delta = quill?.getContents();
  const { text } = deltaToText(delta);
  const sentenceStartIndices = getSentenceStartIndices(delta);

  if (sentenceIndex < 0 || sentenceIndex >= sentenceStartIndices?.length) {
    console.error('Invalid sentence index');
    return;
  }

  const startIndex = sentenceStartIndices[sentenceIndex];

  // Find the end of the sentence by looking for the next sentence start or end of the text
  const endIndex =
    sentenceIndex + 1 < sentenceStartIndices?.length ? sentenceStartIndices[sentenceIndex + 1] : text?.length;

  // Apply highlight format to the range
  quill?.formatText(startIndex, endIndex - startIndex, {
    background: color,
  });
}

export const updateSpecificHighlights = (quillEditor) => {
  const delta = quillEditor.getContents(); // Get current content as Delta
  let modified = false;

  delta.ops.forEach((op) => {
    if (op.attributes && (op.attributes.background === '#facbcb' || op.attributes.background === '#b4f1aa')) {
      op.attributes.background = '#ffffff';
      modified = true;
    }
  });

  if (modified) {
    quillEditor.setContents(delta); // Apply the updated Delta back to the editor
  }
};

export const isHighlightingChange = (delta: any) => {
  for (let i = 0; i < delta.ops?.length; i++) {
    const op = delta.ops[i];

    if (op.attributes && op.attributes.background) {
      const highlightColors = ['#facbcb', '#b4f1aa', '#fffff'];

      if (highlightColors.includes(op.attributes.background)) {
        return true;
      }
    }
  }

  return false;
};

export const sliceHtmlContent = (htmlContent: string, maxLength: number): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);

  let currentLength = 0;
  const toRemove: Node[] = [];

  while (currentLength <= maxLength && walker.nextNode()) {
    const node = walker.currentNode;

    if (node.nodeValue !== null) {
      const remainingLength = maxLength - currentLength;

      if (node.nodeValue?.length > remainingLength) {
        node.nodeValue = node.nodeValue.slice(0, remainingLength);
        currentLength += node.nodeValue?.length;
        break;
      }

      currentLength += node.nodeValue?.length;
    }
  }

  // Remove any subsequent nodes after the maxLength is reached
  while (walker.nextNode()) {
    toRemove.push(walker.currentNode);
  }

  toRemove.forEach((node) => node.parentNode?.removeChild(node));

  return doc.body.innerHTML;
};

export const customRenderers = (color = 'gray5') => ({
  p: (props) => (
    <Text fontSize={'sm'} color={color}>
      {props.children}
    </Text>
  ),
  h1: (props) => (
    <Text fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  h2: (props) => (
    <Text fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  li: (props) => (
    <Text fontSize={'sm'} color={color}>
      {props.children}
    </Text>
  ),
  a: (props) => (
    <Text as='a' fontSize={'sm'} color={color} href={props.href}>
      {props.children}
    </Text>
  ),
  h3: (props: any) => (
    <Text fontSize={'sm'} fontFamily={'Montserrat'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  h4: (props: any) => (
    <Text fontSize={'sm'} fontFamily={'Montserrat'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  ul: (props: any) => (
    <UnorderedList fontSize={'sm'} fontFamily={'Montserrat'} color={color} spacing={2}>
      {props.children}
    </UnorderedList>
  ),
  ol: (props: any) => (
    <OrderedList fontSize={'sm'} fontFamily={'Montserrat'} color={color} spacing={2}>
      {props.children}
    </OrderedList>
  ),
});

export const getSelectedFilesByCollegeId = (collegeId: number, savedFolders): IEssay[] =>
  savedFolders.flatMap((college) => college.files).filter((file) => file.college_id === collegeId);
