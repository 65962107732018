import { userAtom } from 'Atoms/App/user';
import { TOTAL_BACKGROUND_QUESTIONS } from 'Constants/profile';
import { atom } from 'jotai';
import { EnvId } from 'Models/config';

export const isProfileSavingAtom = atom<boolean>(false);
export const isErrorSavingProfileAtom = atom<boolean>(false);

export const profileProgressAtom = atom((get) => {
  const user = get(userAtom);

  const fieldsToCheck = [
    'act',
    'classrank',
    'country',
    'ethnicity',
    'gender',
    'gpa',
    'gradyear',
    'highschool',
    'income',
    'satenglish',
    'satmath',
    'state',
  ].filter((field) => {
    if (user?.country !== 'United States' && field === 'state') {
      return false;
    }

    if (!user?.standardized_tests && ['act', 'satmath', 'satenglish'].includes(field)) {
      return false;
    }

    return true;
  });

  const filteredObj = user
    ? Object.fromEntries(Object.entries(user).filter(([key]) => fieldsToCheck.includes(key)))
    : user;

  const count = Object.values(filteredObj || {})?.filter(
    (value) => value !== null && value !== undefined && value !== ''
  )?.length;

  return Math.ceil((count / Object.entries(filteredObj || {})?.length) * 100);
});

export const backgroundAnswersAtom = atom<any>([]);

export const backgroundProgressAtom = atom((get) => {
  const answers = get(backgroundAnswersAtom);

  if (answers?.length > 0) {
    const progress = answers.reduce((acc: number, ans: any) => {
      if (!(ans?.answer?.trim() === '' || !ans?.answer)) {
        return acc + 1;
      }

      return acc;
    }, 0);

    return progress;
  }

  return 0;
});

export const backgroundProgressPercentageAtom = atom((get) => {
  const progress = get(backgroundProgressAtom);
  return Math.ceil((progress / TOTAL_BACKGROUND_QUESTIONS) * 100);
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  backgroundAnswersAtom.debugLabel = 'background answers';
  backgroundProgressAtom.debugLabel = 'background progress';
  backgroundProgressPercentageAtom.debugLabel = 'background progress percentage';
}
